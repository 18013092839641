<script setup lang="ts">
import {
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElIcon,
} from "element-plus";
import { ArrowDown } from "@element-plus/icons";
// import router from "@/router";
import { useStorage } from "@vueuse/core";
import autolog from "autolog.js";

function logout() {
  // localStorage.removeItem("token");
  // router.replace("/login");
  autolog.log("暂未开放，敬请期待", "info");
}
// function goLogin() {
//   autolog.log("暂未开放，敬请期待", "info");
//   // router.push("/login");
// }
const token = useStorage("token", "");
</script>

<template>
  <div class="BaseUserBox">
    <el-dropdown v-if="token">
      <span class="el-dropdown-link">
        BaseUser
        <el-icon class="el-icon--right">
          <arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <div class="loginOrRegistry" v-else>
      <!-- <span @click="goLogin">注册/登录</span> -->
    </div>
  </div>
</template>

<style lang="less" scoped>
.BaseUserBox {
  cursor: pointer;
  color: var(--theme-text);

  span {
    color: var(--theme-text);
  }

  .loginOrRegistry {
    cursor: pointer;

    span {
      color: var(--theme-active-color);
      padding: 4px 6px;
      border-radius: 8px;
      border: 1px solid var(--theme-active-color);
      font-size: 14px;

      &:hover {
        background: var(--theme-active-color);
        color: #ffffff;
      }
    }
  }
}
</style>
