<template>
  <div class="StoragesComBox">
    <!--地区-->
    <div class="StoragesArea">
      <div class="select">
        <el-select
          v-model="value"
          filterable
          placeholder="地区选择"
          style="width: 240px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="tip">
        <div class="icon">icon</div>
        <div class="title">
          文件存储最大允许20GB，挂载在系统的<span style="color: #f19200"
            >文件目录</span
          >。三个月未登录或者欠费,将会被删除。
        </div>
      </div>
    </div>
    <!--初始化-->
    <div v-if="empty">
      <!--存储-->
      <div class="Freestorage">
        <div class="FreestoragetTitle">
          <div>免费</div>
          <div>付费</div>
          <div class="icon">
            <el-icon><Warning /></el-icon
            ><span style="color: #f19200" class="number">130GB</span>/<span
              >100GB</span
            >
          </div>
        </div>
        <!--进度条-->
        <div class="progress">
          <el-progress :percentage="80" />
        </div>
      </div>
      <!--列表-->
      <div class="StoragesForm">
        <div class="form">
          <div class="btn">
            <div class="formTip">文件与目录数量超过300条,最多显示300条记录</div>
            <el-button type="warning">
              <el-icon><Upload /></el-icon>上传
            </el-button>
          </div>
          <!--表格-->
          <div class="table">
            <el-table :data="tableData">
              <el-table-column fixed prop="date" label="文件名" align="left" />
              <el-table-column prop="name" label="大小" align="left" />
              <el-table-column prop="state" label="更新时间" align="center" />
              <el-table-column fixed="right" label="操作" align="center">
                <template #default>
                  <el-button link type="primary" size="small">下载 </el-button>
                  <el-button link type="primary" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!--数据为空-->
    <div class="initial" v-else>
      <div class="initialContent">
        <div>img</div>
        <div class="initialtitle">
          该区域未分配存储空间，如需配置请点击<span style="color: #f19200"
            >初始化</span
          >
        </div>
        <div class="initialbtn">
          <el-button type="warning">初始化</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const value = ref("");
//下拉框
const options = ref([
  {
    value: "Option1",
    label: "Option1",
  },
  {
    value: "Option2",
    label: "Option2",
  },
  {
    value: "Option3",
    label: "Option3",
  },
  {
    value: "Option4",
    label: "Option4",
  },
  {
    value: "Option5",
    label: "Option5",
  },
]);
//表格数据
const tableData = [
  {
    date: "2016-05-03",
    name: "Tom",
    state: "California",
    city: "Los Angeles",
  },
  {
    date: "2016-05-02",
    name: "Tom",
    state: "California",
  },
  {
    date: "2016-05-04",
    name: "Tom",
    state: "California",
  },
  {
    date: "2016-05-01",
    name: "Tom",
    state: "Californias",
  },
];
//进度条
//初始化
let empty = ref(0);
</script>
<style lang="less" scoped>
.StoragesComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  display: flex;
  flex-direction: column;
  .StoragesArea {
    margin-top: 16px;
    margin-left: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    .select {
      border-radius: 8px;
      margin-right: 22px;
      .el-select {
        width: 105px !important;
      }
    }
    .tip {
      display: flex;
      background: #fff7e8;
      align-items: center;
      .icon {
        padding: 8px 5px 9px 16px;
      }
      .title {
        font-family: AlibabaPuHuiTi_2_55_Regular;
        font-size: 12px;
        color: #1d2129;
        line-height: 17px;
        text-align: left;
        font-style: normal;
        padding-right: 12px;
      }
    }
    ::v-deep .el-select__wrapper {
      cursor: pointer;
      padding: 6px 12px;
    }
  }
  .Freestorage {
    background: #ffffff;
    margin-bottom: 16px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    .FreestoragetTitle {
      display: flex;
      padding: 13px 17px 8px 17px;
      justify-content: space-between;
      .icon {
        display: flex;
        align-items: center;
        .number {
          margin-left: 5px;
        }
      }
    }
    .progress {
      padding-left: 17px;
      padding-right: 17px;
      padding-bottom: 23px;
    }
    ::v-deep .el-progress__text {
      padding-left: 20px;
    }
  }
  .StoragesForm {
    background: white;
    margin-bottom: 24px;
    margin-right: 20px;
    margin-left: 20px;
    .form {
      padding: 12px 25px 0 25px;
      .btn {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 9px;
        align-items: center;
        .formTip {
          margin-right: 10px;
          color: #f19200;
        }
      }
      ::v-deep .el-table .cell {
        padding: 9px 12px;
      }
      ::v-deep .el-table__header-wrapper th {
        background-color: #f2f3f5;
        color: black;
      }
    }
  }
  .initial {
    display: flex;
    background: white;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
    margin-bottom: 20px;
    position: relative;
    align-items: center;
    justify-content: center;
    .initialContent {
      display: flex;
      align-items: center;
      flex-direction: column;
      .initialtitle {
        padding-top: 21px;
        font-size: 14px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        line-height: 20px;
        padding-bottom: 21px;
      }
    }
  }
}
</style>
