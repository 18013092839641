<script setup lang="ts">
import { ref } from "vue";

const recommendList = ref([
  {
    gpuName: "4090 24G",
    cpu: "vCPU 18核 起",
    memory: "48G 起",
    disk: "50G",
    price: "1.98 起",
    priceUnit: "小时",
  },
  {
    gpuName: "4090D 24G",
    cpu: "vCPU 18核 起",
    memory: "48G 起",
    disk: "50G",
    price: "1.98 起",
    priceUnit: "小时",
  },
  {
    gpuName: "3090 24G",
    cpu: "vCPU 14核 起",
    memory: "48G 起",
    disk: "50G",
    price: "1.36 起",
    priceUnit: "小时",
  },
  {
    gpuName: "A800 80G SXM",
    cpu: "vCPU 24核 起",
    memory: "80G 起",
    disk: "50G",
    price: "5.98 起",
    priceUnit: "小时",
  },
]);
</script>

<template>
  <div class="RecommendBox">
    <div class="mainTitle">炼丹会员及租用价格</div>
    <div class="subTitle">
      坚持为您提供服务稳定、价格公道的GPU租用服务。更为学生提供免费升级会员通道，享极具性价比的会员价格。<a
        href="#"
        >如何升级会员？</a
      >
    </div>
    <div class="recommendGrid">
      <div class="recommendItem" v-for="item in recommendList">
        <div class="infos">
          <div class="gpuName">
            {{ item.gpuName }}
          </div>
          <div class="cpu">
            <span>CPU:</span>
            {{ item.cpu }}
          </div>
          <div class="other">
            <div class="memory">
              <span>内存:</span>
              {{ item.memory }}
            </div>
            <div class="disk">
              <span>系统盘:</span>
              {{ item.disk }}
            </div>
          </div>
        </div>
        <div class="price">
          <div>
            ￥{{ item.price }}
            <span class="priceUnit"> /{{ item.priceUnit }} </span>
          </div>
          <div class="getNow">立即租用</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.RecommendBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mainTitle {
    font-size: 36px;
    font-weight: bold;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  .subTitle {
    color: #707984;
    max-width: 665px;
    font-size: 16px;
    text-align: center;
    line-height: 30px;

    a {
      color: var(--theme-active-color);
      text-decoration: none;
    }
  }

  .recommendGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    max-width: 1200px;

    .recommendItem {
      background: #fffbf5;
      box-shadow: 0px 2px 4px 0px rgba(66, 83, 124, 0.15);
      border-radius: 10px;
      border: 1px solid #e5e7eb;
      padding: 18px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .infos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        font-weight: 500;

        .other {
          display: flex;
          justify-content: space-between;
        }
      }

      .gpuName {
        font-size: 18px;
        color: #000000;
        padding-bottom: 20px;
      }

      .cpu,
      .memory,
      .disk {
        &.cpu {
          padding-bottom: 10px;
        }
        font-size: 14px;
        color: #000000;

        span {
          color: #707984;
          padding-right: 20px;
          font-weight: bold;
        }
      }

      .price {
        font-size: 24px;
        font-weight: bold;
        color: var(--theme-active-color);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-left: 72px;
        height: 100%;

        .priceUnit {
          font-size: 14px;
          font-weight: normal;
          color: #000000;
        }

        .getNow {
          width: 104px;
          height: 28px;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid var(--theme-active-color);
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-weight: normal;

          &:hover {
            background: var(--theme-active-color);
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
