<script setup lang="ts">
import { routes } from "@/router";
let consoleLinks = routes[0].children?.filter(
  (route) => route.path == "console",
)[0].children;
</script>

<template>
  <div class="BaseMenuBox">
    <div class="routerLinks">
      <template v-for="route in consoleLinks" :key="route.path">
        <router-link :to="{ name: route.name }" v-if="!route.meta?.hidden">
          <span class="iconfont" :class="route.meta?.icon"></span>
          <span>{{ route.name }}</span>
        </router-link>
      </template>
    </div>
    <div class="routerLinks">
      <template v-for="route in consoleLinks" :key="route.path">
        <router-link :to="{ name: route.name }" v-if="!!route.meta?.hidden">
          <span class="iconfont" :class="route.meta?.icon"></span>
          <span>{{ route.name }}</span>
        </router-link>
      </template>
    </div>
  </div>
</template>

<style lang="less" scoped>
.BaseMenuBox {
  width: var(--menu-width);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 12px;

  .routerLinks {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;

    a {
      padding: 12px 30px;
      width: 100%;
      display: inline-block;
      transition: 0.2s;
      border-radius: 6px;

      .iconfont {
        margin-right: 18px;
      }

      &.router-link-active {
        background-color: var(--theme-active-bg);
        color: var(--theme-active-color);
      }

      &:hover {
        background-color: var(--theme-active-bg);
      }
    }
  }
}
</style>
