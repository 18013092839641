<script setup lang="ts"></script>

<template>
  <div class="InstancesComBox">
    <div class="demo">InstancesCom</div>
  </div>
</template>

<style lang="less" scoped>
.InstancesComBox {
  width: 100%;
  height: 100%;
}
</style>
