import { defineStore } from "pinia";
import { useDark, useToggle } from "@vueuse/core";

const isDark = useDark();
const toggleDark = useToggle(isDark);

export const mainStore = defineStore("mainStore", {
  state: () => ({
    isDarkMode: true,
    mode: "dark" as "dark" | "light",
  }),
  persist: {
    pick: ["mode"],
  },
  getters: {},
  actions: {
    switchTheme(mode?: "dark" | "light") {
      this.isDarkMode =
        mode == "dark" || (mode == undefined && !this.isDarkMode);
      toggleDark(this.isDarkMode);
      this.mode = mode || (this.isDarkMode ? "dark" : "light");
    },
  },
});
