<script setup lang="ts">
import GlobalLogo from "../GlobalLogo/GlobalLogo.vue";
import BaseUser from "./BaseUser/BaseUser.vue";
// import { routes } from "@/router";
import { mainStore } from "@/store";
import { ElSwitch } from "element-plus";
import { Sunny, Moon } from "@element-plus/icons";
import { ref } from "vue";

const store = mainStore();
store.switchTheme("light");
const isDarkMode = ref(store.isDarkMode);
</script>

<template>
  <div class="BaseHeaderBox">
    <div class="leftPart">
      <GlobalLogo color="dark" />
      <!-- <div class="routerLinks">
        <template v-for="route in routes[0].children" :key="route.path">
          <router-link :to="{ name: route.name }" v-if="!route.meta?.hidden">
            <span class="iconfont" :class="route.meta?.icon"></span>
            <span>{{ route.name }}</span>
          </router-link>
        </template>
      </div> -->
    </div>
    <div class="rightPart">
      <div class="themeModeTg" v-show="false">
        <el-switch
          v-model="isDarkMode"
          @change="store.switchTheme()"
          style="--el-switch-on-color: #2c2c2c; --el-switch-off-color: #f2f2f2"
          :active-action-icon="Moon"
          :inactive-action-icon="Sunny"
        />
      </div>
      <BaseUser />
    </div>
  </div>
</template>

<style lang="less" scoped>
.BaseHeaderBox {
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding-left: 40px;

  .leftPart,
  .rightPart {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-right: 10px;
    height: 100%;
  }

  .routerLinks {
    display: flex;
    height: 100%;
    margin-left: 74px;
    gap: 40px;
    align-items: center;

    a {
      display: inline-flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      position: relative;
      transition: 0.2s;
      .iconfont {
        margin-right: 6px;
      }

      &.router-link-active {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: var(--theme-text);
          bottom: 0;
          left: 0;
        }
      }

      @hoverColor: #213547;

      &:not(.router-link-active):hover {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: fade(@hoverColor, 60);
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
