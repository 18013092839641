import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";

import HomeView from "@/pages/HomeView.vue";
import LoginView from "@/pages/LoginView.vue";
import ConsoleView from "@/Layout/BaseLayout/ConsoleView/ConsoleView.vue";
import OfficialView from "@/Layout/BaseLayout/OfficialView/OfficialView.vue";
import InstancesCom from "@/components/InstancesCom/InstancesCom.vue";
import ImagesCom from "@/components/ImagesCom/ImagesCom.vue";
import StoragesCom from "@/components/StoragesCom/StoragesCom.vue";

const routes: readonly RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/home",
    component: HomeView,
    children: [
      {
        path: "home",
        component: OfficialView,
        name: "首页",
        meta: {
          title: "算力自由",
          hidden: true,
        },
      },
      {
        path: "console",
        component: ConsoleView,
        name: "算力",
        meta: {
          title: "控制台-算力自由",
          icon: "icon-GPU",
        },
        redirect: "/console/overview",
        children: [
          {
            path: "overview",
            component: InstancesCom,
            name: "总览",
            meta: {
              title: "实例-算力自由",
              icon: "icon-zonglan",
            },
          },
          {
            path: "instances",
            component: InstancesCom,
            name: "实例",
            meta: {
              title: "实例-算力自由",
              icon: "icon-shili",
            },
          },
          {
            path: "images",
            component: ImagesCom,
            name: "镜像",
            meta: {
              title: "镜像-算力自由",
              icon: "icon-jingxiang",
            },
          },
          {
            path: "storages",
            component: StoragesCom,
            name: "存储",
            meta: {
              title: "存储-算力自由",
              icon: "icon-cunchu",
            },
          },
          {
            path: "bills",
            component: StoragesCom,
            name: "账单中心",
            meta: {
              title: "账单中心-算力自由",
              hidden: true,
              icon: "icon-feiyong",
            },
          },
          {
            path: "user",
            component: StoragesCom,
            name: "个人中心",
            meta: {
              title: "个人中心-算力自由",
              hidden: true,
              icon: "icon-geren1",
            },
          },
        ],
      },
      {
        path: "model",
        component: OfficialView,
        name: "模型",
        meta: {
          title: "算力自由",
          icon: "icon-moxing",
        },
      },
      {
        path: "data",
        component: OfficialView,
        name: "数据",
        meta: {
          title: "算力自由",
          icon: "icon-shuju",
        },
      },
    ],
  },
  {
    path: "/login",
    component: LoginView,
    meta: {
      title: "算力自由-登录",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const noTokenDomainRouterList = ["/login", "/register", "/forget", "/home"]; // 不需要登录的路由
router.beforeEach((to, _, next) => {
  document.title = to.meta.title as string;
  if (noTokenDomainRouterList.includes(to.path)) {
    next();
  } else {
    localStorage.getItem("token") ? next() : next("/login");
  }
});

export { routes };

export default router;
