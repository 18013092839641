<script setup lang="ts">
import router from "@/router";
import autolog from "autolog.js";
import { ElButton } from "element-plus";

function login() {
  localStorage.setItem("token", "fakeToken");
  router.replace("/");
  autolog.log("login success", "success");
}
</script>

<template>
  <div class="LoginViewBox">
    <div class="demo">
      <ElButton type="primary" @click="login">Login</ElButton>
    </div>
  </div>
</template>

<style lang="less" scoped>
.LoginViewBox {
  width: 100%;
  height: 100%;
}
</style>
